* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
::placeholder {
  color: black;
}
body {
  background-color: rgba(242, 248, 250, 1);
}

ul,
p {
  font-family: "Noto Sans", sans-serif;
  line-height: 32px;
  letter-spacing: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inknut Antiqua", sans-serif;
  letter-spacing: 1px;
}
a {
  text-decoration: none;
}
button {
  font-family: "Noto Sans", sans-serif;
}
.myservices:nth-child(1):hover {
  box-shadow: none;
}
.box:hover .inner_box {
  box-shadow: none;
}
#box:hover > #inner_box > svg {
  box-shadow: none;
}
@media only screen and (max-width: 600px) {
  #box:hover > #inner_box > svg {
    box-shadow: 4px 4px 2px 6px rgba(21, 45, 46, 0.98);
  }
}

.animated-image {
  animation: bounce 4s cubic-bezier(0.53, 0.06, 0.58, 1.09) infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes moveAndScale {
  0% {
    transform: translateX(0) translateY(0) scale(1) rotate(0);
  }
  100% {
    transform: translateX(30%) translateY(-50%) scale(3) rotate(-30deg); /* Adjust translateY to counteract the downward movement */
  }
}

.animated-path {
  animation: moveAndScale 2s forwards;
}
